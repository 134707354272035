<template>
  <div class="home">
    <div class="banner">
      <img style="width:100%;" src="../assets/home/53434543534.png" alt="">
      <img class="logo" src="../assets/home/54523534534534.png" alt="">
    </div>
    <div class="product">
      <div class="title">
        {{ $t('home.product.title') }}
      </div>
      <div class="content">
        <div class="des_t">
          <div class="des">
            <div class="des_a">
              {{ $t('home.product.des.a') }}
            </div>
            <div class="des_b">
              {{ $t('home.product.des.b') }}
            </div>
          </div>
          <div>
            <img src="../assets/home/43243552.png" alt="">
          </div>
        </div>
        <div class="des_b">
          <div>
            <img src="../assets/home/3423544363644.png" alt="">
          </div>
          <div class="des">
            <div class="des_c">
              {{ $t('home.product.des.c') }}
            </div>
            <div class="des_d">
              {{ $t('home.product.des.d') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="trait">
      <div class="title">
        {{ $t('home.trait.title') }}
      </div>
      <div>
        <img src="../assets/home/532524525234.png" alt="">
      </div>
    </div>
    <div class="scene">
      <div class="title">
        {{ $t('home.scene.title') }}
      </div>
      <div>
        <img src="../assets/home/435464363.png" alt="">
      </div>
      <div class="content">
        <div class="des">
            <div class="des_title">
              {{ $t('home.scene.tutelage.title') }}
            </div>
            <div class="des_content" v-for="item in ['a','b']" :key="item">
              {{ $t(`home.scene.tutelage.des.${item}`) }}
            </div>
        </div>
        <div class="des">
            <div class="des_title">
              {{ $t('home.scene.real_time.title') }}
            </div>
            <div class="des_content">
              {{ $t('home.scene.real_time.des') }}
            </div>
        </div>
        <div class="des">
            <div class="des_title">
              {{ $t('home.scene.analyze.title') }}
            </div>
            <div class="des_content" v-for="item in ['a','b','c','d']" :key="item">
              {{ $t(`home.scene.analyze.des.${item}`) }}
            </div>
        </div>
      </div>
      <div class="little_title">
        {{ $t('home.scene.little_title') }}
      </div>
      <div>
        <img src="../assets/home/5345353534534.png" alt="">        
      </div>
    </div>
    <div class="honor">
      <div class="title">
        {{ $t('home.honor.title') }}
      </div>
      <div>
        <img src="../assets/home/245235456345243.png" alt="">
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'HomeView',
  components: {
    HelloWorld,
  },
});
</script>
<style lang="less" scoped>

.home{
    max-width: window-width;
    min-width: 1900px;
    font-size: 20px;    
    text-align: center;
    padding-top: 80px;
    .honor{
        background: #F4F4F4;
        padding: 68px 23% 78px 23%;
    }
    .scene{
        padding: 68px 23% 50px 23%;
        .title{
            margin-bottom: 36px;
        }
        .content{
            margin-top: 17px;
            text-align: left;
            .des{
                margin-bottom: 16px;
                .des_title{
                    font-size: 24px;
                    line-height: 33px;
                }
                .des_content{
                    text-indent: 4ch;
                    line-height: 33px;
                    font-size: 18px;
                }
            }
        }
        .little_title{
            margin-top: 62px;
            margin-bottom: 28px;
            font-size: 18px;
            color: #A9A9A9;
            line-height: 26px;
        }
    }
    .trait{
        padding: 68px 23% 48px 23%;
        background: #F4F4F4;
    }
    .product{
        padding: 66px 23% 60px 23%;
        .content{
            color: #A9A9A9;
            font-size: 18px;
            line-height: 32px;
            text-align: left;
            .des_t{
                display: flex;
                margin-bottom: 98px;
                .des{
                    text-indent: 4ch;
                    margin-right: 19px;
                    .des_a{
                        margin-bottom: 20px;
                        text-indent: 4ch;
                    }
                }
            }
            .des_b{
                display: flex;
                .des{
                    text-indent: 4ch;
                    margin-left: 76px;
                    .des_c{
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
    .banner{
        background: #EEEFF1;
        position: relative;
        .logo{
            position: absolute;
            top: 40px;
            left: 23%;
        }
    }
    .title{
        font-size: 36px;
        color: #010101;
        line-height: 52px;
        font-weight: 500;
        margin-bottom: 50px;
    }
}

</style>
